<h2 class="text-center text-4xl font-bold">GEOVISOR PARA EL MONITOREO DE BOFEDALES</h2>

<p class="text-2xl">
  La Autoridad Plurinacional de la Madre Tierra (APMT) ha desarrollado un Geovisor para el monitoreo de Bofedales
  basadas en algoritmos de aprendizaje mediante la plataforma de Google Earth Engine.
  <br />
  En el geovisor se dispone información para toda la región Altoandina, correspondiente: Mes de inicio
  <strong>08</strong>, Mes final <strong>11</strong> y año <strong>2022</strong>.
</p>

<p class="text-2xl">A continuación, se presenta el aplicativo desarrollado.</p>

<iframe *ngIf="link !== null" [src]="safeLink" class="w-full" sandbox="allow-same-origin allow-scripts"></iframe>

<p class="text-2xl">Para acceder al Script del Geovisor el usuario debe seguir los siguientes pasos:</p>

<ul class="text-2xl">
  <li>
    Como primer paso se debe registrarse en la Plataforma Google Earth Engine a través del siguiente enlace
    <a href="https://earthengine.google.com" target="_blank">https://earthengine.google.com</a>, para lo cual se
    necesita tener una cuenta en Gmail.
  </li>
  <li>
    Una vez registrado en Google Earth Engine, puede ingresar al siguiente enlace <br />
    <a href="https://code.earthengine.google.com/b0f731b46871906ae54bc8fb81dcb3d9" target="_blank"
      >https://code.earthengine.google.com/b0f731b46871906ae54bc8fb81dcb3d9</a
    >
  </li>
</ul>

import { Component, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/shared/services/app.layout.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { Resource } from '../../shared/constants';

@Component({
  selector: 'app-menu-template',
  templateUrl: './menu-template.component.html',
  styleUrls: ['./menu-template.component.scss'],
})
export class MenuTemplateComponent implements OnInit {
  model: any[] = [];

  constructor(public layoutService: LayoutService, public permissionService: PermissionService) {}

  ngOnInit(): void {
    this.model = [
      {
        label: '',
        isPublic: true,
        items: [{ label: 'Inicio', icon: 'pi pi-fw pi-home', routerLink: ['/home'], isPublic: true }],
      },
      {
        label: 'Administración',
        isPublic: true,
        items: [
          {
            label: 'Usuarios',
            icon: 'pi pi-fw pi-user',
            resourceName: 'USUARIOS_ADMIN_MENU',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Administración de usuarios',
                icon: 'pi pi-fw pi-user',
                resourceName: 'USUARIOS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/user'],
              },
              {
                label: 'Administración de Roles',
                icon: 'pi pi-fw pi-list',
                resourceName: 'ROLES',
                right: 'canView',
                isPublic: false,
                routerLink: ['/role'],
              },
              {
                label: 'Administración de Permisos del Sistema',
                icon: 'pi pi-fw pi-th-large',
                resourceName: 'PERMISOS_SISTEMA',
                right: 'canView',
                isPublic: false,
                routerLink: ['/system-acl'],
              },
              {
                label: 'Log de Actividad',
                icon: 'pi pi-fw pi-list',
                resourceName: 'LOG_ACTIVIDADES',
                right: 'canView',
                isPublic: false,
                routerLink: ['/log-activity'],
              },
            ],
          },
          {
            label: 'Biblioteca Virtual',
            icon: 'pi pi-fw pi-folder',
            resourceName: 'GESTION_DOCUMENTOS',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Administracion de Documentos',
                icon: 'pi pi-fw pi-folder-open',
                resourceName: 'GESTION_DOCUMENTOS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/documents'],
              },
            ],
          },
        ],
      },
      {
        label: 'Transparencia',
        isPublic: true,
        items: [
          {
            label: 'NDC',
            icon: 'pi pi-fw pi-server',
            resourceName: Resource.MENU_BIG_DATA,
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Sector NDC',
                icon: 'pi pi-fw pi-chart-pie',
                resourceName: Resource.BIG_DATA_SECTORS_NDC,
                right: 'canView',
                isPublic: false,
                routerLink: ['/big-data/sector'],
              },
              {
                label: 'Configuraciones',
                icon: 'pi pi-fw pi-cog',
                resourceName: Resource.MENU_BIG_DATA,
                right: 'canView',
                isPublic: false,
                items: [
                  {
                    label: 'Unidades de Medida',
                    icon: 'pi pi-fw pi-pound',
                    resourceName: Resource.BIG_DATA_UOM_NDC,
                    right: 'canView',
                    isPublic: false,
                    class: 'bg-primary',
                    routerLink: ['/big-data/settings/uom'],
                  },
                ],
              },
            ],
          },
          {
            label: 'Seguimiento',
            icon: 'pi pi-fw pi-directions',
            resourceName: Resource.MENU_BIG_DATA_TRACKING,
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Asignación de Fichas',
                icon: 'pi pi-fw pi-sliders-h',
                resourceName: Resource.BIG_DATA_ASSIGNMENTS,
                right: 'canView',
                isPublic: false,
                routerLink: ['/big-data/tracking/assignments'],
              },
              {
                label: 'Fichas de seguimiento',
                icon: 'pi pi-fw pi-id-card',
                resourceName: Resource.BIG_DATA_TRACKING_CARDS,
                right: 'canView',
                isPublic: false,
                routerLink: ['/big-data/tracking/cards'],
              },
            ],
          },
          {
            label: 'Dashboards',
            icon: 'pi pi-fw pi-palette',
            resourceName: Resource.MENU_BIG_DATA_DASHBOARDS,
            right: 'canView',
            isPublic: false,
            items: [
              {
                label: 'Dashboards',
                icon: 'pi pi-fw pi-palette',
                resourceName: Resource.BIG_DATA_DASHBOARDS,
                right: 'canView',
                isPublic: false,
                routerLink: ['/big-data/dashboards'],
              },
              {
                label: 'Dashboards Publico',
                icon: 'pi pi-fw pi-palette',
                resourceName: Resource.BIG_DATA_DASHBOARDS,
                right: 'canView',
                isPublic: true,
                routerLink: ['/transparencia'],
              },
            ],
          },
          {
            label: 'Reportes',
            icon: 'pi pi-file-export',
            resourceName: Resource.BIG_DATA_REPORTS,
            right: 'canView',
            isPublic: true,
            class: 'bg-primary',
            items: [
              {
                label: 'Reportes',
                icon: 'pi pi-file-export',
                resourceName: Resource.BIG_DATA_REPORTS,
                right: 'canView',
                isPublic: true,
                routerLink: ['/big-data/reports'],
              },
            ],
          },
        ],
      },
      {
        label: 'Sistemas de Vida',
        isPublic: false,
        resourceName: Resource.SDV_DASHBOARDS,
        right: 'canView',
        items: [
          {
            label: 'Sistemas de vida',
            icon: 'pi pi-fw pi-th-large',
            resourceName: Resource.SDV_DASHBOARDS,
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Dashboards',
                icon: 'pi pi-fw pi-chart-bar',
                resourceName: Resource.SDV_DASHBOARDS,
                right: 'canView',
                isPublic: false,
                class: 'bg-primary',
                routerLink: ['/dashboards/sdv'],
              },
              {
                label: 'Categorias',
                icon: 'pi pi-fw pi-tags',
                resourceName: Resource.SDV_CATEGORIES,
                right: 'canView',
                isPublic: false,
                class: 'bg-primary',
                routerLink: ['/dashboards/sdv/categories'],
              },
            ],
          },
        ],
      },
      {
        label: 'Geodatos',
        isPublic: false,
        resourceName: Resource.RECURSOS_CARTOGRAFICOS,
        right: 'canView',
        items: [
          {
            label: 'Interoperabilidad',
            icon: 'pi pi-fw pi-globe',
            resourceName: 'SERVICIOS_MAPAS_WEB',
            right: 'canView',
            isPublic: false,
            routerLink: ['/web-map-service'],
          },
          {
            label: 'Coberturas',
            icon: 'pi pi-fw pi-map',
            resourceName: 'ADMINISTRACION_RECURSOS_CARTOGRAFICOS',
            right: 'canView',
            isPublic: false,
            class: 'bg-primary',
            items: [
              {
                label: 'Coberturas',
                icon: 'pi pi-fw pi-map',
                resourceName: 'RECURSOS_CARTOGRAFICOS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/coverages'],
              },
              {
                label: 'Tematicas',
                icon: 'pi pi-fw pi-book',
                resourceName: 'TEMATICAS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/thematics'],
              },
              {
                label: 'Estilos',
                icon: 'pi pi-fw pi-palette',
                resourceName: 'RECURSOS_CARTOGRAFICOS',
                right: 'canView',
                isPublic: false,
                routerLink: ['/sld'],
              },
            ],
          },
        ],
      },
      {
        label: 'GeoCambio Climatico',
        icon: 'pi pi-fw pi-map',
        resourceName: 'MENU_GEOVISOR',
        right: 'canView',
        isPublic: false,
        class: 'bg-primary',
        items: [
          {
            label: 'GeoCambio Climatico',
            icon: 'pi pi-fw pi-map',
            resourceName: 'INTERNAL_GEOVISOR',
            right: 'canView',
            isPublic: false,
            routerLink: ['/internal/geocambioclimatico'],
          },
          {
            label: 'GeoCambio Climatico externo',
            icon: 'pi pi-fw pi-map',
            resourceName: 'GEOVISOR',
            right: 'canView',
            isPublic: false,
            routerLink: ['/geocambioclimatico'],
          },
        ],
      },
      {
        label: 'Componentes de la Madre Tierra',
        resourceName: 'ENV_COMPONENTS',
        right: 'canView',
        isPublic: false,
        class: 'bg-primary',
        items: [
          {
            label: 'Componentes',
            icon: 'pi pi-fw pi-box',
            resourceName: 'ENV_COMPONENTS',
            right: 'canView',
            isPublic: false,
            routerLink: ['/components'],
          },
          {
            label: 'Indicadores',
            icon: 'pi pi-fw pi-compass',
            resourceName: 'INDICATOR_ENV_COMPONENTS',
            right: 'canView',
            isPublic: false,
            routerLink: ['/components/indicators'],
          },
          {
            label: 'Datos de Coberturas',
            icon: 'pi pi-fw pi-database',
            resourceName: 'ENV_COMPONENTS',
            right: 'canView',
            isPublic: false,
            routerLink: ['/components/coverages'],
          },
        ],
      },
      {
        label: 'Bofedales',
        isPublic: true,
        items: [
          {
            label: 'Monitoreo de bofedales',
            isPublic: true,
            icon: 'pi pi-fw pi-map',
            routerLink: ['/bofedales'],
          },
          {
            label: 'Módulo Generador de Mosaico',
            isPublic: true,
            icon: 'pi pi-fw pi-globe',
            routerLink: ['/mosaico'],
          },
          {
            label: 'Módulo Clasificador',
            isPublic: true,
            icon: 'pi pi-fw pi-globe',
            routerLink: ['/clasificador'],
          },
        ],
      },
    ];
  }
}
